import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    opacity: 1;
    transform: scale(1);
  }
  70% {
    opacity: 0;
    transform: scale(0.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  width: 518px;
  border-radius: 12px;
  background: linear-gradient(90deg, #f093fb 0%, #f5576c 100%);
  padding: 4px 4px 8px;
  & > div {
    overflow: hidden;
    background: ${(props) => props.theme.colors.white};
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    gap: 10px;
    & > textarea {
      background: ${(props) => props.theme.colors.white};
      height: 40px;
      width: 100%;
      padding: 9px 10px;
      font-size: 17px;
      font-weight: 400;
      line-height: normal;
      resize: none;
      transition: all 0.5s;
      &:focus {
        height: 84px;
      }
    }
    & > button > div > svg {
      animation: ${animation} 2s ease-in-out infinite;
    }
  }
`;
