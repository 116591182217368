/* eslint-disable */
import hardest1 from '@/images/Sections/Continuous Integration 1.png';
import hardestSecondary1 from '@/images/Sections/Continuous Integration 1_0.png';
import hardest2 from '@/images/Sections/Continuous Integration 2.png';
import hardest3 from '@/images/Sections/Continuous Integration 3.png';
import hardest4 from '@/images/Sections/Continuous Integration 4.png';
import hardest5 from '@/images/Sections/Continuous Integration 5.png';
import hardest6 from '@/images/Sections/Continuous Integration 6.png';
import hardest7 from '@/images/Sections/Continuous Integration 7.png';
import hardest8 from '@/images/Sections/Continuous Integration 8.png';
import hardestSecondary8_0 from '@/images/Sections/Continuous Integration 8_0.png';
import hardestSecondary8_1 from '@/images/Sections/Continuous Integration 8_1.png';
import discover0 from '@/images/Sections/discover_0.jpg';
import discover1 from '@/images/Sections/discover_1.jpg';
import discover2 from '@/images/Sections/discover_2.png';

import MainAppTemplatesImage from '@/images/Products/main-app-templates.png';
import MainVisualAppBuilderImage from '@/images/Products/main-visual-app-builder.png';
import MainInBrowserAppPreviewImage from '@/images/Products/main-in-browser-apps-preview.png';
import MainContentManagementSystem from '@/images/Products/main-content-management-system.png';
import MainContinuousIntegrationImage from '@/images/Products/main-continuous-integration.png';
import MainBackendAsAServiceImage from '@/images/Products/main-backend-as-a-service.png';
import MainAppLandingPageImage from '@/images/Products/main-app-landing-page.png';
import MainStoreManagerImage from '@/images/Products/main-store-manager.png';
import MainTermsAndPolicesGeneratorImage from '@/images/Products/main-terms-and-policies-generator.png';
import MainScreenshotsGeneratorImage from '@/images/Products/main-screenshots-generator.png';
import MainAIAppBuilder from '@/images/Products/main-ai-app-builder.png';

import { Name } from '../general';
import { PRODUCTS } from '../Products/ProductsInfo';

export interface IMainPageProduct {
  title: string;
  description: string;
  icon: Name;
  image: any;
  imageWidth: number;
  to?: string;
  list: string[];
}

export const mainPageProducts = () => {
  const productsCopy = JSON.parse(JSON.stringify(PRODUCTS));
  const updatedProductsCopy = productsCopy.map((product) => {
    switch (product.title) {
      case 'App Templates':
        product = {
          ...product,
          title: 'App Templates',
          description: 'Stop writing boilerplate code with',
          list: [
            'Well-architected iOS Swift & Android Kotlin project templates bundled into pre-built modules',
            'Complete freedom of native mobile apps',
            'Serverless backend and App landing page included',
            'Adjustable in a no-code Visual App Builder',
          ],
          image: MainAppTemplatesImage,
          imageWidth: 491,
        };
        break;
      case 'Visual App Builder':
        product = {
          ...product,
          description: 'Get your MVP in minutes with',
          list: [
            'A no-code way to develop your app and update the source code',
            'Adjustable app templates, ready screens and components',
          ],
          image: MainVisualAppBuilderImage,
          imageWidth: 491,
        };
        break;
      case 'In-Browser Apps preview':
        product = {
          ...product,
          description: 'Make app testing more simple with',
          list: [
            'Preview native apps in browser without opening Xcode or Android Studio',
          ],
          image: MainInBrowserAppPreviewImage,
          imageWidth: 486,
        };
        break;
      case 'Content Management System':
        product = {
          ...product,
          description: 'Put your business data to work with',
          list: [
            'Headless CMS for your app and beyond',
            'Content models and digital assets management',
            'Unified data collections form various sources',
            'Everything stays in sync.',
          ],
          image: MainContentManagementSystem,
          imageWidth: 566,
        };
        break;
      case 'Continuous Integration':
        product = {
          ...product,
          description: 'Forget about additional DevOps with',
          list: [
            'Everything your app needs to build and deploy in one place',
            'Ready-made pipelines for mobile apps, backend and landing page',
            'App Store and Google Play integrations',
            'Runs on Apple Silicon',
          ],
          image: MainContinuousIntegrationImage,
          imageWidth: 607.75,
        };
        break;
      case 'Backend-as-a-service':
        product = {
          ...product,
          description: 'Take your app backend out of the box with',
          list: [
            'Serverless backend that scales automatically',
            'Runs on isolated Google Cloud or AWS projects',
            'Updates automatically with your app',
            'No maintenance expenses. Pay as you go',
            'No lock-ins on our services. Project ownership is transferable',
          ],
          image: MainBackendAsAServiceImage,
          imageWidth: 420,
        };
        break;
      case 'App Landing Page':
        product = {
          ...product,
          description: 'Avoid publishing pitfalls with',
          list: [
            'Appstore-optimized application homepage with all the necessary content',
            'Updated simultaneously with you app',
            'SEO-optimized, multilingual',
            'Support form included',
          ],
          image: MainAppLandingPageImage,
          imageWidth: 552,
        };
        break;
      case 'Store Manager':
        product = {
          ...product,
          description: 'Publish your app faster with',
          list: [
            'All required stores content in one place',
            'AppStore & Google Play integrations',
            'Links to product page, terms & policies, support page and screenshots generated and pre-filled in advance.',
          ],
          image: MainStoreManagerImage,
          imageWidth: 495,
        };
        break;
      case 'Terms & Policies Generator':
        product = {
          ...product,
          description: 'Make your app compliant with',
          list: [
            'Humanly understandable terms and policies',
            'All in compliance with AppStore & Google Play guidelines',
            'US / EU Privacy Law Coverage',
            'Updated automatically with your app',
          ],
          image: MainTermsAndPolicesGeneratorImage,
          imageWidth: 416,
        };
        break;
      case 'Screenshots Generator':
        product = {
          ...product,
          description: 'Get more downloads with',
          list: [
            'A professional app store screenshots editor with templates',
            'Best practices to make your screenshots catch the eye of users and increase in-app conversions',
            'Screenshots are updated automatically with your app',
          ],
          image: MainScreenshotsGeneratorImage,
          imageWidth: 471,
        };
        break;
      case 'AI App Builder':
        product = {
          ...product,
          description: 'Supercharge your productivity with',
          list: [
            'Visual App Builder with AI co-pilot',
            'Native Swift & Kotlin code generator',
            'App Backend generator',
            'App Landing page generator',
            'Screenshots & App stores content generator',
          ],
          image: MainAIAppBuilder,
          imageWidth: 550,
        };
        break;
      default:
        break;
    }
    delete product.cards;
    return product;
  });
  return updatedProductsCopy;
};

export interface IHardestData {
  title: string;
  description: string;
  image: any;
  secondaryImages?: {
    image: any;
    left?: number;
    right?: number;
    top: number;
    width: number;
  }[];
}

export const THE_HARDEST_DATA: IHardestData[] = [
  {
    title: 'Pick a template',
    description:
      'Select a template that suits you best. Name the project and define the infrastructure type',
    image: hardest1,
    secondaryImages: [
      {
        image: hardestSecondary1,
        left: -10,
        top: 30,
        width: 78,
      },
    ],
  },
  {
    title: 'Build visually',
    description:
      'Develop you app in a visual no-code builder or by tuning the source code via Github',
    image: hardest2,
  },
  {
    title: 'Preview',
    description:
      "Use In-Browser App Preview to test your app. You don't need to open Xcode or Android Studio",
    image: hardest3,
  },
  {
    title: 'Manage source code',
    description:
      'Check out the native mobile applications, their backend, and the app landing page CodePlatform generated and deployed for you.',
    image: hardest4,
  },
  {
    title: 'CI/CD',
    description:
      'Build and deploy everything your app needs. No additional DevOps or infrastructure management.',
    image: hardest5,
  },
  {
    title: 'Connect',
    description:
      'Connect AppStore & Google Play with a simple integration. We will do all the hidden job to help you with publishing.',
    image: hardest6,
  },
  {
    title: 'Generate content',
    description:
      "Generate and control app stores content all in one place. We'll build your app and prepare all the links to product page, terms & policies, support page and screenshots.",
    image: hardest7,
  },
  {
    title: 'Publish',
    description:
      "Publish in a click. Manage app content with embedded CMS. We've covered all the main pitfalls in publishing process.",
    image: hardest8,
    secondaryImages: [
      {
        image: hardestSecondary8_0,
        right: -31,
        top: 25,
        width: 64,
      },
      {
        image: hardestSecondary8_1,
        right: -31,
        top: 54,
        width: 64,
      },
    ],
  },
];

export const DISCOVER_DATA = [
  {
    title: 'Firebase App Starter',
    icon: Name.MAIN_PAGE_DISCOVER_APP_STARTER,
    image: discover0,
    description: 'Get started with a universal Firebase app',
  },
  {
    title: 'Video Content App',
    icon: Name.MAIN_PAGE_DISCOVER_VIDEO_CONTENT_APP,
    image: discover1,
    description: 'Generate a platform for subscription-based ',
  },
  {
    title: 'ChatGPT Assistant App Starter',
    icon: Name.MAIN_PAGE_DISCOVER_CHAT_GPT,
    image: discover2,
    description: 'Generate your own GPT-powered application',
  },
];
