import React, { useEffect, useState } from 'react';

import { AI } from '@/components/AI';
import { Button, Icon, Name } from '@/components/general';
import * as styled from './InitialMessage.styled';

const initialMessageKey = 'initialMessage';

export const InitialMessage: React.FC = () => {
  const isBrowser = () => typeof window !== 'undefined';
  const initialMessageFromSessionStorage = isBrowser()
    ? sessionStorage.getItem(initialMessageKey) || ''
    : '';
  const [initialMessage, setInitialMessage] = useState(
    initialMessageFromSessionStorage,
  );

  useEffect(() => {
    sessionStorage.setItem(initialMessageKey, initialMessage);
  }, [initialMessage]);

  return (
    <styled.Wrapper>
      <div>
        <textarea
          value={initialMessage}
          onChange={(e) => setInitialMessage(e.target.value)}
          placeholder="Describe the app you want to build..."
        />
        <AI intent="start-building" initialMessage={initialMessage}>
          <Button $leftIcon={<Icon name={Name.SECOND_ORDER_AI} />}>
            Generate
          </Button>
        </AI>
      </div>
    </styled.Wrapper>
  );
};
