import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';
import { StyledButton, BorderLinkStyle } from '@/components/general';

interface IButton {
  width: number;
}

export const Button = styled(StyledButton)<IButton>`
  width: ${(props: IButton) => props.width}px;
  height: 50px;
`;

interface ITableBox {
  open: boolean;
  $tableVisibleHeight?: number;
  $tableHeight?: number;
}

export const TableBox = styled.div<ITableBox>`
  overflow: hidden;
  transition: height 0.8s ease-in;
  height: ${(props: ITableBox) =>
    props.open ? props.$tableHeight : props.$tableVisibleHeight}px;
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.colors.borderColor};

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    display: none;
  }
`;

export const Table = styled.table`
  max-width: 1100px;
`;

export const TableRow = styled.tr`
  align-items: stretch;
  display: flex;
  height: 100%;
`;

interface ITableCell {
  $index: number;
  $rowIndex?: number;
}

export const TableHeadCell = styled.th<ITableCell>`
  text-align: left;
  min-height: 115px;
  width: calc(100% / 3);
  padding: ${(props: ITableCell) =>
    props.$index === 0
      ? '0 0 0 70px'
      : props.$index === 1
      ? '0'
      : '0 70px 0 0'};
  background-color: ${(props) =>
    props.$index === 1
      ? props.theme.colors.offGreen
      : props.$index === 2
      ? props.theme.colors.blue
      : 'transparent'};
  color: ${(props) =>
    props.$index === 2 ? props.theme.colors.white : props.theme.colors.black};
  font-size: 30px;
  line-height: 35px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    font-size: 20px;
    line-height: 23px;
    min-height: 95px;
  }
`;

export const TableCell = styled.td<ITableCell>`
  width: calc(100% / 3);
  min-height: 100%;
  padding: ${(props: ITableCell) =>
    props.$index === 0
      ? '0 0 0 70px'
      : props.$index === 1
      ? '0'
      : '0 70px 0 0'};
  background-color: ${(props) =>
    props.$index === 1
      ? props.theme.colors.offGreen
      : props.$index === 2
      ? props.theme.colors.blue
      : 'transparent'};
  color: ${(props) =>
    props.$index === 2 ? props.theme.colors.white : props.theme.colors.black};
`;

export const TableCellContentFull = styled.div`
  height: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderGray};
`;

export const TableCellContent = styled.div<ITableCell>`
  height: 100%;
  padding: ${(props: ITableCell) =>
    props.$index === 0
      ? '20px 35px 20px 0'
      : props.$index === 1
      ? '20px 55px'
      : '20px 0px 20px 35px'};
  padding-top: ${(props: ITableCell) => (props.$rowIndex === 1 ? '0' : '20px')};
  font-size: ${(props: ITableCell) => (props.$index === 2 ? 16 : 18)}px;
  line-height: ${(props: ITableCell) => (props.$index === 2 ? 19 : 21)}px;
  font-weight: ${(props) => props.theme.weights.regular};

  & p {
    &:nth-child(2) {
      padding-top: 10px;

      @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
        padding-top: 15px;
      }
    }
  }
  & p {
    padding-top: 27px;
  }

  & > b {
    font-size: 18px;
    line-height: 21px;
  }

  & a {
    ${BorderLinkStyle}
    color: ${(props) => props.theme.colors.white}!important;
    &:before {
      background: ${(props) => props.theme.colors.white}!important;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    font-size: ${(props: ITableCell) => (props.$index === 2 ? 14 : 16)}px;
    line-height: ${(props: ITableCell) => (props.$index === 2 ? 17 : 19)}px;

    & > b {
      font-size: 16px;
      line-height: 19px;
    }
  }
`;

export const TableHeadCellContent = styled.h3<ITableCell>`
  display: flex;
  align-items: center;
  height: 100%;
  padding: ${(props: ITableCell) =>
    props.$index === 0
      ? '20px 35px 0px 0'
      : props.$index === 1
      ? '20px 55px 0 55px'
      : '20px 0px 0px 35px'};
`;

export const MobileTableBox = styled.div`
  display: none;
  margin-top: 50px;
  max-width: calc(100vw - 56px);
  overflow-x: scroll;
  border: 1px solid ${(props) => props.theme.colors.borderGray};
  border-radius: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    display: block;
  }
`;

export const MobileTable = styled.table``;

interface IMobileTableRow {
  $index: number;
}

export const MobileTableRow = styled.tr<IMobileTableRow>`
  background-color: ${(props) =>
    props.$index === 1
      ? props.theme.colors.offGreen
      : props.$index === 2
      ? props.theme.colors.blue
      : 'transparent'};
  color: ${(props) =>
    props.$index === 2 ? props.theme.colors.white : props.theme.colors.black};
  font-size: 16px;
  line-height: 21px;
  font-weight: ${(props) => props.theme.weights.regular};
`;

interface IMobileTableHeadCell {
  $rowIndex: number;
}

export const MobileTableHeadCell = styled.th<IMobileTableHeadCell>`
  position: sticky;
  left: 0px;
  min-width: 200px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderGray};
  padding: 20px 10px;
  background-color: ${(props) =>
    props.$rowIndex === 1
      ? props.theme.colors.offGreen
      : props.$rowIndex === 2
      ? props.theme.colors.blue
      : props.theme.colors.white};
  border-radius: ${(props: IMobileTableHeadCell) =>
    props.$rowIndex === 0
      ? '20px 0 0 0'
      : props.$rowIndex === 2
      ? '0 0 0 20px'
      : '0'};
  font-size: 20px;
  line-height: 23px;
  box-shadow: inset -20px 0 20px -20px rgb(0 0 0 / 20%);

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    min-width: 150px;
  }
`;

export const MobileTableCell = styled.td`
  min-width: 200px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderGray};
  border-right: 1px solid ${(props) => props.theme.colors.borderGray};
  padding: 20px 10px;
`;

export const ShowButtonBox = styled.div`
  margin-top: 25px;

  & > span {
    ${BorderLinkStyle}
    font-size: 15px;
    color: ${(props) => props.theme.colors.secondaryBlack}!important;
    &:before {
      background: ${(props) => props.theme.colors.secondaryBlack}!important;
      bottom: -2px;
    }
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    display: none;
  }
`;

export const TableSection = styled.div<{ $mainPage?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  ${(props) =>
    props.$mainPage && {
      return: `
  ${TableBox} {
    overflow: hidden;
  }
  ${TableBox} {
    border-radius: 0px;
    border: none;
  }
  & thead {
    ${TableRow} {
      &:first-child {
        background: linear-gradient(90deg, #e6e9f0 0%, #eef1f5 100%);
        border-radius: 8px;
        padding: 10px 15px;
      }
    }
  }
  & tbody {
    ${TableRow} {
      &:first-child {
        margin-top: 15px;
      }
    }
  }
  ${TableHeadCell}, ${TableCell} {
    min-height: 0px;
    padding: 0;
    background: transparent;
    color: ${props.theme.colors.secondaryBlack};

    &:first-child {
      width: 26.5%;

      @media (max-width: ${props.theme.breakpoints.xl}px) {
        width: 25.5%;
      }
    }
    &:nth-child(2) {
      width: 24.5%;

      @media (max-width: ${props.theme.breakpoints.xl}px) {
        width: 28%;
      }
    }
    &:last-child {
      width: 46.5%;
    }

    & h3 {
      padding: 0;
      ${text.FS17}

      @media (max-width: ${props.theme.breakpoints.xl}px) {
        font-size: 15px;
      }
    }
    & h3 {
      font-weight: ${props.theme.weights.bold};
    }

  }

  ${TableCell} {
    &:first-child {
      & > div {
        margin-left: 15px;
        padding-right: 15px;
        & > div {
          font-weight: ${props.theme.weights.bold};
        }
      }
    }
    &:nth-child(2) {
      & > div {
        padding-left: 7.5px;
        padding-right: 30px;
        & > div {
          font-weight: ${props.theme.weights.bold};
          color: ${props.theme.colors.grayText};
        }
      }
    }
    &:last-child {
      & > div {
        margin-right: 15px;
        & > div {
          color: ${props.theme.colors.grayText};
        & b {
          font-weight: ${props.theme.weights.bold};
          color: ${props.theme.colors.blue};
        }
        }
      }
    }
  }
  ${TableCellContent} {
    padding: 15px 0;
    ${text.FS17}

    & b {
      ${text.FS17}
    }

    @media (max-width: ${props.theme.breakpoints.xl}px) {
      font-size: 15px;

      & b {
        font-size: 15px;
      }
    }

    & a {
      ${BorderLinkStyle}
      color: ${props.theme.colors.blue}!important;
      &:before {
        background: ${props.theme.colors.blue}!important;
      }
    }
  }
  ${TableCellContentFull} {
    border-bottom: 1px solid ${props.theme.colors.borderColor};
    & br {
      line-height: 50px; 
    }
  }
  ${Table} {
    max-width: 1134px;
  }
  ${ShowButtonBox} {
    margin-top: 40px;
    & > span {
      font-size: 17px;
    }
  }`,
    }}
`;
